<template>
  <div class="modal-card">
    <section class="modal-card-body">
      <div v-if="!expand">
        <div class="columns is-mobile">
          <div class="column">
            <p class="subtitle has-text-primary">You're almost there</p>
          </div>
        </div>
        <p>
          To start using Cazana's Trade Portal and all of the great benefits
          that it provides, please accept our
          <a @click.prevent="expand = true">terms and conditions</a> by pressing
          agree below.
        </p>
      </div>
      <div class="content" v-else>
        <p class="subtitle has-text-primary">Terms and conditions</p>
        <p>
          Cazana is a trading name of UK Vehicle Limited, a company registered
          in England and Wales with registered number 09671692. The Dealer is a
          member of the motor trade wishing to access Cazana’s Trade Portal at
          trade.cazana.com.
        </p>
        <p>
          By accepting these terms and conditions and accessing Cazana’s Trade
          Portal, you agree to enter into the following agreement:
        </p>
        <p class="title is-6 has-text-primary">AGREEMENT</p>
        <p class="heading">BACKGROUND</p>
        <p>
          Cazana has developed a technology platform “Cazana Trade Portal” (as
          defined below) which provides information on vehicles, the ability to
          buy provenance checks in bulk and the ability to research and access
          Cazana’s enhanced Trade Vehicle Profile Pages.
        </p>
        <p>
          Subject to the terms and conditions of this Agreement, Cazana agrees
          to provide the Services to Dealer and Dealer agrees to give Cazana
          access to the Vehicle Content.
        </p>
        <p class="title is-6 has-text-primary">AGREED TERMS</p>
        <p class="heading">INTERPRETATION</p>
        <p>Definitions:</p>
        <p>
          "Affiliate" means any entity that directly or indirectly controls, is
          controlled by or is under common control with another entity.
        </p>
        <p>
          “Cazana Trade Portal” means the system accessible at the URL
          trade.cazana.com which provides the ability for Trade users to receive
          Trade VPPs, subscribe to Cazana’s provenance checks in bulk and view a
          list of vehicles previously checked as well as other functionality
          added from time to time.
        </p>
        <p>
          "DMS Provider" means a third party appointed by Dealer to provide a
          dealer management system which contains the Vehicle Content.
        </p>
        <p>
          "Insolvency Event" means in relation to a party, the occurrence of any
          of the following events:
        </p>
        <p>
          a party suspends, or threatens to suspend, payment of its debts or is
          unable to pay its debts as they fall due or admits inability to pay
          its debts or is deemed unable to pay its debts within the meaning of
          section 123 of the Insolvency Act 1986;
        </p>
        <p>
          a party commences negotiations with all or any class of its creditors
          with a view to rescheduling any of its debts, or makes a proposal for
          or enters into any compromise or arrangement with its creditors other
          than for the sole purpose of a scheme for a solvent amalgamation of
          that party with one or more other companies or the solvent
          reconstruction of that party;
        </p>
        <p>
          a petition is filed, a notice is given, a resolution is passed, or an
          order is made, for or on connection with the winding up of that party
          other than for the sole purpose of a scheme for a solvent amalgamation
          of that party with one or more other companies or the solvent
          reconstruction of that party;
        </p>
        <p>
          an application is made to court, or an order is made, for the
          appointment of an administrator or if a notice of intention to appoint
          an administrator is given or if an administrator is appointed over
          that party;
        </p>
        <p>
          the holder of a qualifying floating charge over the assets of that
          party has become entitled to appoint or has appointed an
          administrative receiver;
        </p>
        <p>
          a person becomes entitled to appoint a receiver over the assets of
          that party or a receiver is appointed over the assets of that party;
        </p>
        <p>
          a creditor or encumbrancer of that party attaches or takes possession
          of, or a distress, execution, sequestration or other such process is
          levied or enforced on or sued against, the whole or any part of its
          assets and such attachment or process is not discharged within 14
          days;
        </p>
        <p>
          any event occurs, or proceeding is taken, with respect that party in
          any jurisdiction to which it is subject that has an effect equivalent
          or similar to any of the events mentioned in a) to g) (inclusive).
        </p>
        <p>
          "Intellectual Property Rights" means patents, rights to inventions,
          copyright and related rights, trade marks, business names and domain
          names, goodwill, rights in designs, rights in computer software,
          database rights, rights to use, and protect the confidentiality of,
          confidential information (including know-how and trade secrets) and
          all other intellectual property rights, in each case whether
          registered or unregistered and including all applications and rights
          to apply for and be granted, renewals or extensions of, and rights to
          claim priority from, such rights and all similar or equivalent rights
          or forms of protection which subsist or will subsist now or in the
          future in any part of the world.
        </p>
        <p>
          "Services" means the services to be provided by Cazana pursuant to
          this Agreement: access to the Cazana Trade Portal and Trade VPPs.
        </p>
        <p>
          "Services Start Date" means the day on which Cazana is to start
          provision of the Services, as set out in this Agreement Details.
        </p>
        <p>
          “Trade VPP” means the version of the VPP tailored to Trade users which
          contains additional information including owner and registration plate
          changes.
        </p>
        <p>
          "Vehicle Content" means all images, descriptions, specifications, data
          (including pricing and mileage) and all other information held by
          Dealer and related to the stock of vehicles listed or offered for sale
          by Dealer from 1 January 2010 onwards.
        </p>
        <p>
          “Vehicle Profile Page” or “VPP” means the detailed pages of history
          that Cazana provides at URLs starting https://cazana.com/uk/car
        </p>
        <p class="title is-6 has-text-primary">INTERPRETATION</p>
        <p>
          Clause headings shall not affect the interpretation of this Agreement.
        </p>
        <p>
          A person includes a natural person, corporate or unincorporated body
          (whether or not having separate legal personality).
        </p>
        <p>
          Unless the context otherwise requires, words in the singular shall
          include the plural and in the plural shall include the singular.
        </p>
        <p>
          This Agreement shall be binding on, and enure to the benefit of, the
          parties to this Agreement and their respective personal
          representatives, successors and permitted assigns, and references to
          any party shall include that party’s personal representatives,
          successors and permitted assigns.
        </p>
        <p>
          Any reference to a statute, statutory provision or statutory
          instrument or regulation includes a reference to that statute,
          statutory provision or statutory instrument or regulation together
          with all rules and regulations made under it as from time to time
          amended, consolidated or re-enacted.
        </p>
        <p>
          Any words following the terms including, include, in particular, for
          example or any similar expression shall be construed as illustrative
          and shall not limit the sense of the words, description, definition,
          phrase or term preceding those terms.
        </p>
        <p class="title is-6 has-text-primary">COMMENCEMENT AND TERM</p>
        <p>
          The Agreement shall commence on the date it is accepted by the Dealer
          and continue until either party gives to the other not less than 12
          months' written notice to terminate.
        </p>
        <p class="title is-6 has-text-primary">SUPPLY OF SERVICES</p>
        <p>In supplying the Services, Cazana shall:</p>
        <p>perform the Services with reasonable care and skill; and</p>
        <p>use reasonable endeavours to perform the Services.</p>
        <p class="title is-6 has-text-primary">DEALER OBLIGATIONS</p>
        <p>
          If Cazana's performance of its obligations under this Agreement is
          prevented or delayed by any act or omission of Dealer, its agents,
          subcontractors, consultants or employees, Cazana shall:
        </p>
        <p>
          not be liable for any costs, charges or losses sustained or incurred
          by Dealer that arise directly or indirectly from such prevention or
          delay;
        </p>
        <p>
          be entitled to recover any additional costs, charges or losses Cazana
          sustains or incurs that arise directly or indirectly from such
          prevention or delay
        </p>
        <p>Dealer shall:</p>
        <p>provide Cazana with access to the Vehicle Content either:</p>
        <p>directly from Dealer’s website; or</p>
        <p>via the Dealer’s DMS Provider;</p>
        <p>
          be responsible for any charges levied by Dealer’s DMS Provider for
          supplying the Vehicle Content to Cazana pursuant to clause 4.1.1b);
        </p>
        <p>co-operate with Cazana in all matters relating to the Services;</p>
        <p>
          provide, in a timely manner, such information as Cazana may reasonably
          require, and ensure that it is accurate and complete in all material
          respects.
        </p>
        <p class="title is-6 has-text-primary">WARRANTIES</p>
        <p>Dealer represents and warrants that:</p>
        <p>
          Vehicle Content will comply with all applicable laws, regulations and
          codes of practice and will not infringe any Intellectual Property
          Rights of any third party;
        </p>
        <p>
          Vehicle Content will be complete and accurate and Dealer shall
          promptly update or correct Vehicle Content on becoming aware of any
          errors or inaccuracies;
        </p>
        <p>
          it has the authority to market and sell the vehicles contained in the
          Vehicle Content; and
        </p>
        <p>
          it holds all necessary authorities, consents and licences necessary to
          use, display, reproduce, publish the Vehicle Content and has authority
          to and grants Cazana (and its Affiliates) a licence of the Vehicle
          Content on the terms set out in 6.1.
        </p>
        <p class="title is-6 has-text-primary">INTELLECTUAL PROPERTY</p>
        <p>
          Dealer grants to Cazana (and its current and future Affiliates) a
          perpetual, irrevocable, non-exclusive, transferable, sub-licensable
          licence to use, copy, re-produce, modify, publish and create
          derivative works from the Vehicle Content.
        </p>
        <p>
          Dealer shall indemnify, defend and hold Cazana and its officers,
          directors, agents, employees, representatives and successors, harmless
          from and against all claims, allegations, causes of action or demands
          that are presented to or brought against Cazana by a third party, and
          any losses, liabilities and penalties (including reasonable legal
          fees) arising out of such third party claims, allegations, causes of
          action, or demands in connection with or relating to (i) breach of
          clause 5; and/or (ii) infringement of a third party's rights
          (including any Intellectual Property Rights) arising out of, or in
          connection with, the receipt or use of the Vehicle Content by Cazana.
        </p>
        <p>
          Other than as expressly set out in this Agreement, Dealer is not
          granted any rights in the Cazana Trade Portal or any other technology
          of Cazana.
        </p>
        <p class="title is-6 has-text-primary">CONFIDENTIALITY</p>
        <p>
          Each party shall, during the term of this licence and thereafter, keep
          confidential all, and shall not use for its own purposes (other than
          implementation of this licence) nor without the prior written consent
          of the other disclose to any third party (except its professional
          advisors or as may be required by any law or any legal or regulatory
          authority) any, information of a confidential nature (including trade
          secrets and information of commercial value) which may become known to
          such party from the other party and which relates to the other party
          or any of its Affiliates, unless that information is public knowledge
          or already known to such party at the time of disclosure, or
          subsequently becomes public knowledge other than by breach of this
          licence, or subsequently comes lawfully into the possession of such
          party from a third party. Each party shall use its reasonable
          endeavours to prevent the unauthorised disclosure of any such
          information.
        </p>
        <p class="title is-6 has-text-primary">LIMITATION OF LIABILITY</p>
        <p>
          Nothing in this Agreement limits either party’s liability (if any) to
          the other for: personal injury or death resulting from negligence;
          fraud; or any matter for which it would be illegal to exclude or to
          attempt to exclude its liability.
        </p>
        <p>
          Subject to clause 9.1, Cazana’s total aggregate liability to Dealer
          under or in connection with this Agreement (whether in contract, tort
          including negligence, breach of statutory duty, restitution or
          otherwise) in respect of all and any loss or damage howsoever caused
          will be limited to [insert figure].
        </p>
        <p>
          In no event shall Cazana be under any liability (including warranty
          liability) to Dealer for any of the following types of losses (whether
          those losses arise directly in the normal course of business or
          otherwise): pure economic loss; loss of profits; loss of or damage to
          materials stored in goods supplied; loss of business; loss of revenue;
          loss of contract; loss or depletion of goodwill and/or business
          opportunity; loss of anticipated earnings or savings or like loss;
          wasted management, operational or other time; or any special, indirect
          or consequential losses.
        </p>
        <p class="title is-6 has-text-primary">TERMINATION</p>
        <p>
          Without affecting any other right or remedy available to it, either
          party to this Agreement may terminate it with immediate effect by
          giving written notice to the other party if:
        </p>
        <p>
          the other party commits a material breach of any term of this
          Agreement which breach is irremediable or (if such breach is
          remediable) fails to remedy that breach within a period of 30 days
          after being notified to do so; or
        </p>
        <p>the other party suffers an Insolvency Event.</p>
        <p>
          Without affecting any other right or remedy available to it, Cazana
          may terminate this Agreement with immediate effect by giving written
          notice to Dealer in the event that it is discovered that the Dealer is
          not a member of the motor trade.
        </p>
        <p>On termination of this Agreement for whatever reason:</p>
        <p>
          any provision of this Agreement that expressly or by implication is
          intended to come into or continue in force on or after termination or
          expiry of this Agreement shall remain in full force and effect; and
        </p>
        <p>
          termination or expiry of this Agreement shall not affect any of the
          rights, remedies, obligations or liabilities of the parties that have
          accrued up to the date of termination or expiry, including the right
          to claim damages in respect of any breach of this Agreement which
          existed at or before the date of termination or expiry.
        </p>
        <p class="title is-6 has-text-primary">FORCE MAJEURE</p>
        <p>
          Neither party shall be in breach of this Agreement nor liable for
          delay in performing, or failure to perform, any of its obligations
          under this Agreement if such delay or failure results from events,
          circumstances or causes beyond its reasonable control. In such
          circumstances the affected party shall be entitled to a reasonable
          extension of the time for performing such obligations. If the period
          of delay or non-performance continues for 30 days or more, the party
          not affected may terminate this Agreement by giving 30 days’ written
          notice to the other party.
        </p>
        <p class="title is-6 has-text-primary">ASSIGNMENT AND SUBCONTRACTING</p>
        <p>
          Neither party shall assign, transfer, mortgage, charge, subcontract,
          declare a trust of or deal in any other manner with any or all of its
          rights and obligations under this Agreement without the prior written
          consent of the other party (such consent not to be unreasonably
          withheld or delayed).
        </p>
        <p class="title is-6 has-text-primary">WAIVER</p>
        <p>
          Failure to exercise, or any delay in exercising, any right or remedy
          provided under this Agreement or by law shall not constitute a waiver
          of that or any other right or remedy, nor shall it preclude or
          restrict any further exercise of that or any other right or remedy.
        </p>
        <p>
          No single or partial exercise of any right or remedy provided under
          this Agreement or by law shall preclude or restrict the further
          exercise of that or any other right or remedy.
        </p>
        <p class="title is-6 has-text-primary">ENTIRE AGREEMENT</p>
        <p>
          This Agreement and any non-disclosure agreement entered into between
          the parties constitutes the entire agreement between the parties and
          supersedes and extinguishes all previous drafts, agreements,
          arrangements and understandings between them, whether written or oral,
          relating to its subject matter.
        </p>
        <p>
          Each party acknowledges that, in entering into this Agreement, it does
          not rely on any statement, representation (whether innocent or
          negligent), assurance or warranty of any person other than as
          expressly set out in this Agreement.
        </p>
        <p>
          Nothing in the Agreement shall limit or exclude any liability for
          fraud.
        </p>
        <p class="title is-6 has-text-primary">VARIATION</p>
        <p>
          No variation of this Agreement shall be effective unless it is in
          writing and signed by the parties (or their authorised
          representatives).
        </p>
        <p class="title is-6 has-text-primary">SEVERANCE</p>
        <p>
          If any provision of this Agreement is held to be invalid or
          unenforceable by any judicial or other competent authority, all other
          provisions of this Agreement will remain in full force and effect and
          will not in any way be impaired.
        </p>
        <p>
          If any provision of this Agreement is held to be invalid or
          unenforceable but would be valid or enforceable if some part of the
          provision were deleted, the provision in question will apply with the
          minimum modifications necessary to make it valid and enforceable.
        </p>
        <p class="title is-6 has-text-primary">COUNTERPARTS</p>
        <p>
          This Agreement may be executed in any number of counterparts, each of
          which shall constitute an original, and all the counterparts shall
          together constitute one and the same agreement.
        </p>
        <p class="title is-6 has-text-primary">THIRD PARTY RIGHTS</p>
        <p>
          This Agreement is made for the benefit of the parties to it and their
          successors and permitted assigns and is not intended to benefit, or be
          enforceable by, anyone else.
        </p>
        <p>
          The parties may terminate, rescind or vary this Agreement without the
          consent of any person who is not a party to this Agreement.
        </p>
        <p class="title is-6 has-text-primary">NOTICES</p>
        <p>
          Any notice or other communication given or made under this Agreement
          shall be in writing (which shall include email) and delivered to the
          other party at its registered address (as shown at the start of this
          Agreement), or any replacement addresses for the purpose provided by
          one party to the other from time to time;
        </p>
        <p>
          Any notice so sent shall be deemed to have been duly given if sent by
          personal delivery or courier upon delivery at the address of the
          relevant party, if sent by prepaid airmail post four (4) business days
          after the date of posting and if sent by email upon receipt of a ‘read
          receipt’ or any acknowledgement from the party receiving the notice.
        </p>
        <p class="title is-6 has-text-primary">
          GOVERNING LAW AND JURISDICTION
        </p>
        <p>
          This Agreement and any dispute or claim arising out of or in
          connection with it or its subject matter or formation (including
          non-contractual disputes or claims) shall be governed by and construed
          in accordance with the law of England.
        </p>
        <p>
          The parties irrevocably agree that the courts of England shall have
          exclusive jurisdiction to settle any dispute or claim that arises out
          of or in connection with this Agreement or its subject matter or
          formation (including non-contractual disputes or claims).
        </p>
      </div>
      <div v-if="isExperian">
        <p>1.1 You shall:</p>
        <p>
          1.1.1 keep a secure password for your use of the Services and keep
          your password confidential.
        </p>
        <p>
          1.1.2 Notify Cazana immediately if you become aware of any
          unauthorised use or access to any part of the Service by any third
          party.
        </p>
        <p>
          1.1.3 ensure that adequate procedures are in place in accordance with
          best computing practice with regard to data security, accuracy and
          back-up, and will be responsible for backing up all its data you may
          use in connection with the Service; and
        </p>
        <p>
          1.1.4 be responsible for ensuring that you are adequately protected
          against any virus or other harmful component entering your systems and
          for ensuring that there is no unauthorised use of the Cazana Product
          or data.
        </p>
        <p>
          1.1.5 comply with all applicable law and regulations with respect to
          your use of the Service.
        </p>
        <p>1.2 You shall not:</p>
        <p>
          1.2.1 access all or any part of the Services and Documentation in
          order to build a product or service which competes with the Service;
        </p>
        <p>
          1.2.2 without the express prior written consent of Cazana license,
          sell, rent, lease, transfer, assign, distribute, disclose, publically
          display or otherwise commercially exploit The Service or
          Documentation;
        </p>
        <p>
          1.2.3 except to the extent permitted by law, attempt to copy, modify,
          duplicate, create derivative works from, frame, mirror, republish,
          download, display, transmit, or distribute all or any portion of the
          Software or Files in any form or media or by any means, or attempt to
          de-compile, reverse compile, disassemble, reverse engineer or
          otherwise reduce to human-perceivable form all or any part of the
          Software or Files; or access or tamper with non-public areas of the
          Service.
        </p>
      </div>
    </section>
    <footer class="modal-card-foot has-background-white">
      <div class="field is-grouped">
        <div class="control is-expanded">
          <button
            class="button is-fullwidth is-success"
            :class="{ 'is-loading': loading }"
            @click="accept"
          >
            I accept
          </button>
        </div>
        <div class="control is-expanded">
          <button class="button is-fullwidth is-danger" @click="decline">
            I decline
          </button>
        </div>
        <div class="control" v-if="expand">
          <button class="button is-text" @click="expand = false">
            Go back
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'ContractGeneral',
  data() {
    return {
      expand: false,
      loading: false,
      error: false
    }
  },
  computed: {
    isExperian() {
      return this.$experian
    }
  },
  methods: {
    decline() {
      this.$store.dispatch('auth/logOut').then(() => {
        this.$modal.close()
        this.$router.push({ name: 'auth-login' })
        this.$notify('You must accept our terms')
      })
    },
    async accept() {
      this.loading = true

      try {
        await this.$store.dispatch('auth/acceptTerms')
        this.$modal.close()
      } catch (err) {
        this.error = true
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
